import { Column, Row } from "@react-tiny-grid/core"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import styled from "styled-components"

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 auto;

  & > div {
    max-width: 500px;
    width: 45%;
    margin-bottom: 20px;
  }

  @media (max-width: 769px) {
    flex-direction: column;

    & > div {
      max-width: 100%;
      width: 100%;
    }
  }
`

const ShadowBox = styled.div`
  box-shadow: 3px 3px 12px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 25px;
  background-color: ${({ color }) => color};
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
`

export default function ProductsSection() {
  return (
    <>
      <h1 className="head">OUR PRODUCTS</h1>
      <br />
      <h1 className="sub-head">
        Represent the Next Generation flexible and easy-to-use Web Forensic
        solution!
      </h1>
      <br /> <br />
      <Container>
        <ShadowBox color="#fff">
          <h2 className="text-center">EVIQUIRE DESKTOP</h2>
          <br />
          <br />
          <StaticImage
            src="../../images/eq-desktop.png"
            width={160}
            height={160}
            quality={100}
          />
          <br />
          <br />
          <p className="text-justify">
            EVIQUIRE Desktop version is compatible with Windows and Linux.
            <br />
            And of course, you can run it on virtual machines too.
          </p>
        </ShadowBox>
        <ShadowBox color="#fff">
          <h2 className="text-center">EVIQUIRE CLOUD</h2>
          <br />
          <br />
          <StaticImage
            src="../../images/eq-cloud.png"
            width={160}
            height={160}
            quality={100}
          />
          <br />
          <br />
          <p className="text-justify">
            EVIQUIRE Cloud lets you use the software without worrying about any
            installation.
            <br />
            Just log in and start acquiring evidence!
          </p>
        </ShadowBox>
      </Container>
      <Row>
        <Column>
          <ShadowBox color="#1c365e">
            <h3 className="text-center text-secondary">Best Value for Money</h3>
            <br />
            <p className="text-justify text-white">
              Our subscription model offers the most advantageous combination of
              cost, quality and sustainability to meet customer requirements.
            </p>
          </ShadowBox>
        </Column>

        <Column>
          <ShadowBox color="#1c365e">
            <h3 className="text-center text-secondary">Reliable</h3>
            <br />
            <p className="text-justify text-white">
              Our product is designed and built to offer you the quickest and
              most stable way to acquire online evidence. Every time. Anytime.
            </p>
          </ShadowBox>
        </Column>

        <Column>
          <ShadowBox color="#1c365e">
            <h3 className="text-center text-secondary">Innovative</h3>
            <br />
            <p className="text-justify text-white">
              Our product is developed using the latest technologies to provide
              the fastest Cross-Platform solution available in the market,
              including Next-Generation Blockchains for timestamping.
            </p>
          </ShadowBox>
        </Column>
      </Row>
      <br /> <br /> <br />
    </>
  )
}
