import { Column, Row } from "@react-tiny-grid/core"
import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"

const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff;
  box-shadow: 3px 3px 12px rgba(0, 0, 0, 0.25);
  padding: 25px 15px;
  margin: 5px;
  border-radius: 10px;
  height: 100%;
  text-align: justify;

  & > h2 {
    color: #1c365e;
    font-size: 1.2em;
  }

  & > p {
    width: 100%;
  }
`

const Button = styled(Link)`
  background-color: ${({ background }) => background};
  border-radius: 12px;
  padding: 12px 30px;
  color: ${({ color }) => (color ? color : "white")};
  text-decoration: none;
  text-align: center;
  font-weight: bold;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  transition: transform 0.5s ease-in-out;

  &:hover {
    opacity: 0.9;
    transform: scale(1.1);
  }
`

const ExternalLink = styled.a`
  background-color: ${({ background }) => background};
  border-radius: 12px;
  padding: 12px 30px;
  color: ${({ color }) => (color ? color : "white")};
  text-decoration: none;
  text-align: center;
  font-weight: bold;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  transition: transform 0.5s ease-in-out;
  cursor: pointer;

  &:hover {
    opacity: 0.9;
    transform: scale(1.1);
  }
`

const ButtonWithoutLink = styled.span`
  background-color: ${({ background }) => background};
  border-radius: 12px;
  padding: 12px 30px;
  color: ${({ color }) => (color ? color : "white")};
  text-decoration: none;
  text-align: center;
  font-weight: bold;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  transition: transform 0.5s ease-in-out;
  cursor: pointer;

  &:hover {
    opacity: 0.9;
    transform: scale(1.1);
  }
`

export default function Call4ActionSection() {
  return (
    <>
      <h1 className="head">Join Us Today</h1>
      <br />
      <h1 className="sub-head">Pick the one best for you</h1>
      <br />
      <Row breakpoints={[769]}>
        <Column>
          <Box>
            <h2>Want to Start Free?</h2>
            <p>
              Download Eviquire Community! Free.
              <br /> <br />
              The limitations of this edition are on the pricing page.
            </p>
            <br />
            <Button to="/download" background="green">
              Download Community!
            </Button>
          </Box>
        </Column>

        <Column>
          <Box>
            <h2>Buy Now!</h2>
            <p>
              Subscribe to one of our paid plans to fully enjoy the software
              features. By paying you also helps us develop more cool features.
              Win-win situation.
            </p>
            <br />
            <ExternalLink
              href={process.env.GATSBY_MY_PORTAL}
              background="#F9C712"
              color="#1c365e"
            >
              Get Started
            </ExternalLink>
          </Box>
        </Column>

        <Column>
          <Box>
            <h2>Need more Info?</h2>
            <p>
              We welcome your questions, suggestions and requests. Let's talk.
            </p>
            <br />
            <ButtonWithoutLink
              background="#fff"
              color="#1c365e"
              onClick={() => window.HelpWidget("open")}
            >
              Contact Us!
            </ButtonWithoutLink>
          </Box>
        </Column>
      </Row>
      <br />
      <br />
      <br />
    </>
  )
}
