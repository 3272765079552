import { Column, Row } from "@react-tiny-grid/core"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { Zoom } from "react-reveal"
import styled from "styled-components"

const Box = styled.div`
  box-shadow: 3px 3px 12px rgba(0, 0, 0, 0.25);
  background-color: #ffffffcc;
  border-radius: 10px;
  height: 100%;
  padding-bottom: 25px;
  transition: transform 0.5s ease-in-out;

  & h2,
  & .text-bold {
    color: #1c365e;
  }

  &:hover {
    color: #fff;
    background-color: #1c365e;
    transform: scale(1.1);

    & h2,
    & .text-bold {
      color: #fff;
    }
  }
`

const BoxHead = styled.div`
  display: flex;
  padding: 20px;
  justify-content: space-between;
  align-items: center;
  min-height: 170px;
`

const HeadText = styled.h2`
  flex: 1;
  text-align: center;
  font-size: 1.2em;
`

const Body = styled.div`
  margin-top: -5px;
  padding: 0 20px;
`

export default function UsersSection() {
  return (
    <>
      <h1 className="head">WHO WILL BENEFIT FROM USING EVIQUIRE</h1>
      <br />
      <h1 className="sub-head">
        For specialists around the world, we make Web Forensic acquisition
        simple, reliable and fast!
      </h1>
      <br /> <br />
      <Row breakpoints={[769]} className="h100">
        <Column>
          <Zoom>
            <Box>
              <BoxHead>
                <StaticImage
                  src="../../images/users/forensics-experts.svg"
                  loading="eager"
                  quality={100}
                  width={120}
                  alt="Forensic Experts"
                />
                <HeadText>Forensic Experts</HeadText>
              </BoxHead>
              <Body>
                <span className="text-bold">Preserve</span> your customer&#39;s
                online evidence as fast as possible before they get{" "}
                <span className="text-bold">canceled!</span>
              </Body>
            </Box>
          </Zoom>
        </Column>

        <Column>
          <Zoom>
            <Box>
              <BoxHead>
                <StaticImage
                  src="../../images/users/journalists.svg"
                  loading="eager"
                  quality={100}
                  width={120}
                  alt="Journalists"
                />
                <HeadText>Journalists</HeadText>
              </BoxHead>
              <Body>
                Protect your groundbreaking case evidence from being treated as
                <span className="text-bold"> fake news</span> by freezing your
                public sources.
              </Body>
            </Box>
          </Zoom>
        </Column>

        <Column>
          <Zoom>
            <Box>
              <BoxHead>
                <StaticImage
                  src="../../images/users/hr-and-due-diligence-teams.svg"
                  loading="eager"
                  quality={100}
                  width={120}
                  alt="HR &amp; Due Diligence Teams"
                />
                <HeadText>HR &amp; Due Diligence Teams</HeadText>
              </BoxHead>
              <Body>
                Reinforce{" "}
                <span className="text-bold">
                  Background Checks for professional hiring clearance
                </span>{" "}
                or investigate a{" "}
                <span className="text-bold">breach of T&amp;C or NDA.</span>
              </Body>
            </Box>
          </Zoom>
        </Column>
      </Row>
      <Row breakpoints={[769]} className="h100">
        <Column>
          <Zoom>
            <Box>
              <BoxHead>
                <StaticImage
                  src="../../images/users/law-enforcement.svg"
                  loading="eager"
                  quality={100}
                  width={120}
                  alt="Law Enforcement"
                />
                <HeadText>Law Enforcement</HeadText>
              </BoxHead>
              <Body>
                During any{" "}
                <span className="text-bold">police investigation,</span> the
                preservation of online content evidence is made easy.
              </Body>
            </Box>
          </Zoom>
        </Column>

        <Column>
          <Zoom>
            <Box>
              <BoxHead>
                <StaticImage
                  src="../../images/users/private-investigators.svg"
                  loading="eager"
                  quality={100}
                  width={120}
                  alt="Private Investigators"
                />
                <HeadText>Private Investigators</HeadText>
              </BoxHead>
              <Body>
                Collect and <span className="text-bold">preserve</span> online
                information in a matter of a click!
              </Body>
            </Box>
          </Zoom>
        </Column>

        <Column>
          <Zoom>
            <Box>
              <BoxHead>
                <StaticImage
                  src="../../images/users/osint-investigators.svg"
                  loading="eager"
                  quality={100}
                  width={120}
                  alt="OSINT Investigators"
                />
                <HeadText>OSINT Investigators</HeadText>
              </BoxHead>
              <Body>
                Investigate{" "}
                <span className="text-bold">
                  Online events, frauds, scams, suspicious online activity or
                  fake news
                </span>
              </Body>
            </Box>
          </Zoom>
        </Column>
      </Row>
      <Row breakpoints={[769]} className="h100">
        <Column>
          <Zoom>
            <Box>
              <BoxHead>
                <StaticImage
                  src="../../images/users/copyright-trademark-lawyers.svg"
                  loading="eager"
                  quality={100}
                  width={120}
                  alt="Copyright &amp; Trademark Lawyers"
                />
                <HeadText>Copyright &amp; Trademark Lawyers</HeadText>
              </BoxHead>
              <Body>
                Investigate and preserve evidence of{" "}
                <span className="text-bold">Copyright</span> and
                <span className="text-bold"> Trademark Infringement!</span>
              </Body>
            </Box>
          </Zoom>
        </Column>

        <Column>
          <Zoom>
            <Box>
              <BoxHead>
                <StaticImage
                  src="../../images/users/patents-i.p-law-firms-legal-departments.svg"
                  loading="eager"
                  quality={100}
                  width={120}
                  alt="Patents &amp; I.P.  Law Firms &amp; Legal Departments"
                />
                <HeadText>
                  Patents &amp; I.P. Law Firms &amp; Legal Departments
                </HeadText>
              </BoxHead>
              <Body>
                Collect Evidence related to{" "}
                <span className="text-bold">
                  Patents, social media reputational damages
                </span>{" "}
                or{" "}
                <span className="text-bold">
                  intellectual property infringement.
                </span>
              </Body>
            </Box>
          </Zoom>
        </Column>

        <Column>
          <Zoom>
            <Box>
              <BoxHead>
                <StaticImage
                  src="../../images/users/cyber-threat-intelligence-expert.svg"
                  loading="eager"
                  quality={100}
                  width={120}
                  alt="Cyber Threat Intelligence Expert"
                />
                <HeadText>Cyber Threat Intelligence Expert</HeadText>
              </BoxHead>
              <Body>
                Investigate the <span className="text-bold">Dark Web</span> and
                preserve <span className="text-bold">cyber criminal</span>{" "}
                traces to build your case.
              </Body>
            </Box>
          </Zoom>
        </Column>
      </Row>
      <br /> <br /> <br />
    </>
  )
}
