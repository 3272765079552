import React from "react"
// import { StaticImage } from "gatsby-plugin-image"
import { Column, Row } from "@react-tiny-grid/core"
import styled from "styled-components"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  line-height: 2rem;
  background-color: #fff;
  padding: 25px;
  border-radius: 10px;
  height: 100%;
`

// const ExternalLink = styled.a`
//   background-color: ${({ background }) => background};
//   border-radius: 12px;
//   padding: 12px 40px;
//   color: ${({ color }) => (color ? color : "white")};
//   text-decoration: none;
//   text-align: center;
//   font-weight: bold;
//   box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
//   transition: transform 0.5s ease-in-out;

//   &:hover {
//     opacity: 0.9;
//     transform: scale(1.1);
//   }
// `

export default function HeroSection() {
  return (
    <>
      <h1 className="head">WHAT IS EVIQUIRE</h1>
      <br />
      <h1 className="sub-head">
        Eviquire is the First Blockchain Native Forensic Web Browser.
      </h1>
      <br />
      <Row>
        <Column>
          <Container>
            <p className="text">
              EVIQUIRE is the perfect solution to help you quickly collect
              online evidence without compromising compliance with forensic
              standards and best practices.
            </p>
            <br />
            <p className="text">
              All collected artifacts are organized under dedicated case files,
              and an acquisition report is created to help you have all the
              information summarized. As a bonus, we timestamp in blockchain the
              evidence package.
            </p>
            <br />
            <p className="text">
              Our software is developed to fulfill ISO IEC 27037 2012 standard
              on identifying, collecting, acquiring and preserving digital
              evidence.
            </p>
          </Container>
        </Column>

        {/* <Column>
          <Container>
            <StaticImage
              src="../../images/logo.png"
              loading="eager"
              width={180}
              height={180}
              alt="Product Image"
            />
            <br />
            <h2 className="sub-head">Eviquire is Coming Soon!</h2>
            <br />
            <ExternalLink
              href="https://forms.clickup.com/36741373/f/13187x-2587/QIO3JTZZ9NAL9272NT"
              target="_blank"
              background="#F9C712"
              color="#1c365e"
            >
              NOTIFY ME
            </ExternalLink>
          </Container>
        </Column> */}
      </Row>
      <br /> <br /> <br />
    </>
  )
}
